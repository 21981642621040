import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerSection from "../../components/Global/TopBanner"
import ServiceIntro from "../../components/Service/ServiceIntro"
import SectionRow from "../../components/Service/SectionRow"
import { SERVICE } from "../../components/Service/data"

const EcommercePage = ({ data, location }) => {
  const ecomData = SERVICE["wordpress"]

  return (
    <Layout>
      <SEO
        title={ecomData.title}
        keywords={[`wordpress`, `woocommerce`, `ecommerce`, `cms`]}
        description={ecomData.description}
      />
      <BannerSection
        img={data.img.childImageSharp.fluid}
        title="Our Services"
      />
      <ServiceIntro title={ecomData.title} subtitle={ecomData.subtitle} />

      {ecomData.sections.map((s, i) => (
        <SectionRow
          key={s.header}
          header={s.header}
          content={s.content}
          iconPosition={i % 2 === 0 ? "right" : "left"}
        />
      ))}
    </Layout>
  )
}

export const query = graphql`
  {
    img: file(relativePath: { eq: "service-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
export default EcommercePage
